
import { Component, Vue, Prop } from 'vue-property-decorator';

import { AdminService, UserService } from '@/services';
import { ICauseCategorySettings } from '@/types/cause.types';
import { UserModule } from '@/store/modules';

@Component
export default class AdminCauseCategories extends Vue {
  @Prop() causeCategories!: ICauseCategorySettings[];

  categoryImageFile = null;
  categoryUploadedImage: any = '';
  dialog = false;
  isUpdateSettings = false;
  createMode = false;

  defaultData = {
    id: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: null,
    description: '',
    name: '',
    primaryColor: '',
    secondaryColor: '',
    categoryImageUrl: '',
    categoryImageHeight: 0,
    categoryImageWidth: 0,
    priority: 1000
  };
  editedItem: ICauseCategorySettings = this.defaultData;
  defaultItem: ICauseCategorySettings = this.defaultData;
  editedIndex = -1;

  headers = [
    { text: 'NAME', value: 'name' },
    { text: 'BACKGROUND COLOR', value: 'primaryColor' },
    { text: 'TEXT COLOR', value: 'secondaryColor' },
    { text: 'CATEGORY IMAGE', value: 'categoryImageUrl' },
    { text: 'DESCRIPTION', value: 'description' },
    { text: 'PRIORITY', value: 'priority' },
    { text: 'ACTIONS', value: 'actions', sortable: false }
  ];

  onFilePickedCategory(e: any): void {
    this.categoryImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.categoryUploadedImage = fr.result;
    });
  }

  editItem(item: ICauseCategorySettings): void {
    this.createMode = false;
    this.editedIndex = this.causeCategories.indexOf(item);
    this.categoryImageFile = null;
    this.categoryUploadedImage = null;
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  createNewCategoryDialog(): void {
    this.categoryImageFile = null;
    this.categoryUploadedImage = null;
    this.createMode = true; // Switch to create mode
    this.editedItem = Object.assign({}, this.defaultData);
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  async deleteCategory(): Promise<void> {
    this.isUpdateSettings = true;

    try {
      await AdminService.deleteCauseCategory(this.editedItem.id);
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
      this.isUpdateSettings = false;
      return;
    }

    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCategories');
  }

  async save(): Promise<void> {
    this.isUpdateSettings = true;
    let formData = new FormData();
    if (this.categoryImageFile) formData.append('categoryImageUrl', this.categoryImageFile as any);
    formData.append('description', this.editedItem.description as any);
    formData.append('name', this.editedItem.name);
    formData.append('primaryColor', this.editedItem.primaryColor as any);
    formData.append('secondaryColor', this.editedItem.secondaryColor as any);
    formData.append('priority', this.editedItem.priority as any);
    formData.append('categoryImageHeight', this.editedItem.categoryImageHeight as any);
    formData.append('categoryImageWidth', this.editedItem.categoryImageWidth as any);

    await AdminService.updateCauseCategory(this.editedItem.id, formData);

    let user = await UserService.fetchUser();
    UserModule.setUser(user);

    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCategories');
  }

  async createNewCategory(): Promise<void> {
    this.isUpdateSettings = true;
    let formData = new FormData();
    if (this.categoryImageFile) formData.append('categoryImageUrl', this.categoryImageFile as any);
    formData.append('description', this.editedItem.description as any);
    formData.append('name', this.editedItem.name);
    formData.append('primaryColor', this.editedItem.primaryColor as any);
    formData.append('secondaryColor', this.editedItem.secondaryColor as any);
    formData.append('priority', this.editedItem.priority as any);
    formData.append('categoryImageHeight', this.editedItem.categoryImageHeight as any);
    formData.append('categoryImageWidth', this.editedItem.categoryImageWidth as any);

    try {
      await AdminService.createCauseCategory(formData);
    } catch (e: any) {
      const errorMsg = this.$getError(e);
      this.$notifyError(errorMsg);
      this.isUpdateSettings = false;
      return;
    }

    this.isUpdateSettings = false;
    this.close();
    this.$emit('getCategories');
  }
}
